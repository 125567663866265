<template>
  <app-table
    :headings="headers"
    :items="records"
    :loading="fetching"
    :empty-text="emptyText"
  >
    <template v-slot:real_name="{ item }">
      <avatar size="25" with-name :user="item"></avatar>
    </template>
    <template v-slot:status="{ item }">
      <chip-in label v-if="item.on_work"></chip-in>
      <chip-brb label v-else-if="item.on_break"> </chip-brb>
      <chip-out label v-else-if="item.on_out"></chip-out>
    </template>
    <template v-slot:overall_work="{ item }">
      <span>
        {{ item.overall_work || 0 | secToHuman(" hours", " minutes") }}
      </span>
    </template>
    <template v-slot:actions="{ item }">
      <icon-list icon="mdi-dots-horizontal">
        <v-list dense>
          <v-list-item @click="$emit('navigate', item)">
            <v-list-item-title class="xtext-[#2E2E2E]">
              <v-icon color="#2E2E2E" left> mdi-eye-circle-outline </v-icon>
              View Logs
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="eventDispatch('add-pto', item)"
            v-if="$can.and('manage_user_ptos')"
          >
            <v-list-item-title class="xtext-[#2E2E2E]">
              <v-icon color="#2E2E2E" left> mdi-plus-circle-outline </v-icon>
              Add PTO
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="eventDispatch('add-time', item)"
            v-if="$can.and('manage_user_time')"
          >
            <v-list-item-title class="xtext-[#2E2E2E]">
              <v-icon color="#2E2E2E" left> mdi-plus-circle-outline </v-icon>
              Add Time
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </icon-list>
    </template>
  </app-table>
</template>

<script>
export default {
  props: {
    emptyText: {
      type: String,
      default: "No records found for the selected year and month",
    },
    fetching: { type: Boolean, default: false },
    records: { type: Array, default: () => [] },
  },
  data() {
    return {
      headers: [
        { text: "Member", value: "real_name" },
        { text: "Email Address", value: "email" },
        { text: "Type", value: "type" },
        { text: "Status", value: "status" },
        { text: "Work Time", value: "overall_work" },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    eventDispatch(eventName, item) {
      this.$event.$emit(eventName, item);
    },
  },
};
</script>

<style lang="scss" scoped></style>
